import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Private from "./Private";
import Public from "./Public";

import Login from "../Pages/Login";
import Registration from "../Pages/Registration";

import Home from "../Pages/Home";
import Transactions from "../Pages/Transactions"
import sendMoney from "../Pages/Transactions/sendMoney"
import NewTransaction from "../Pages/Transactions/newTransaction"
import ViewDetail from "../Pages/Transactions/viewDetail";
import Vendors from "../Pages/Vendors"
import NewVendor from "../Pages/Vendors/newVendor";
import NotesLabel from "../Pages/NotesLabel"
import NewNoteLabel from "../Pages/NotesLabel/newNoteLabel";
import SendMoney from "../Pages/Transactions/sendMoney";

const Routing = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Public>
              <Login />
            </Public>
          }
        />
        <Route path="auth">
          <Route
            path="login"
            element={
              <Public>
                <Login />
              </Public>
            }
          />
          <Route
            path="register"
            element={
              <Public>
                <Registration />
              </Public>
            }
          />
        </Route>
        <Route path="dashboard">
          <Route
            path="home"
            element={
              <Private>
                <Home />
              </Private>
            }
          />
        </Route>
        <Route path="transactions">
          <Route
            path="list"
            element={
              <Private>
                <Transactions />
              </Private>
            }
          />
          <Route
            path="send-money"
            element={
              <Private>
                <SendMoney />
              </Private>
            }
          />
          <Route
            path="create-transaction"
            element={
              <Private>
                <NewTransaction />
              </Private>
            }
          />
          <Route
            path="transaction-detail"
            element={
              <Private>
                <ViewDetail />
              </Private>
            }
          />
        </Route>
        <Route path="vendors">
          <Route
            path="list"
            element={
              <Private>
                <Vendors />
              </Private>
            }
          />
          <Route
            path="add-new-vendors"
            element={
              <Private>
                <NewVendor />
              </Private>
            }
          />
        </Route>
        <Route path="notes">
          <Route
            path="list"
            element={
              <Private>
                <NotesLabel />
              </Private>
            }
          />
          <Route
            path="add-new-note"
            element={
              <Private>
                <NewNoteLabel />
              </Private>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
