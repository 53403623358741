import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const nameService = createApi({
  reducerPath: "names",
  tagTypes: "name",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const authorization = reducers.authReducer?.userToken;
      headers.set("authorization", authorization ? `Bearer ${authorization}` : "");
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => {
    return {

      //   Create New Name
      createName: builder.mutation({
        query: (data) => {
          return {
            url: "/api/create-names",
            method: "POST",
            body: data
          };
        },
        invalidatesTags: ["name"],
      }),

      //   Get the List of Names
      getNamesList: builder.query({
        query: () => {
          return {
            url: "/api/allNames",
            method: "GET",
          };
        },
        providesTags: ["name"],
      }),

      //   Update the Name
      updateName: builder.mutation({
        query: (formData) => {
          console.log("formData....", formData)
          return {
            url: `/api/name/${formData._id}`,
            method: "PUT",
            body: formData,
          };
        },
        invalidatesTags: ["name"],
      }),

    };
  },
});

export const {
  useCreateNameMutation,
  useGetNamesListQuery,
  useUpdateNameMutation,
} = nameService;

export default nameService;
