import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const vendorService = createApi({
  reducerPath: "vendors",
  tagTypes: "vendor",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const authorization = reducers.authReducer?.userToken;
      headers.set("authorization", authorization ? `Bearer ${authorization}` : "");
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => {
    return {
      //   Create New Vendor
      createVendor: builder.mutation({
        query: (data) => {
          return { url: "/api/create-vendor", method: "POST", body: data };
        },
        invalidatesTags: ["vendor"],
      }),

      //   Delete a Vendor
      deleteVendor: builder.mutation({
        query: (id) => {
          return { url: `/api/vendor/${id}`, method: "DELETE" };
        },
        invalidatesTags: ["vendor"],
      }),

      //   Update the Vendor
      updateVendor: builder.mutation({
        query: (data) => {
          return {
            url: `/api/vendor/${data.Id}`,
            method: "PUT",
            body: data,
          };
        },
        invalidatesTags: ["vendor"],
      }),

      //   Get the List of vendors
      getVendorsList: builder.query({
        query: () => {
          return {
            url: "/api/allVendors",
            method: "GET",
          };
        },
        providesTags: ["vendor"],
      }),

      //   Get the Single Vendor
      fetchVendor: builder.query({
        query: (id) => {
          return {
            url: `/api/vendor/${id}`,
            method: "GET",
          };
        },
        providesTags: ["vendor"],
      }),
    };
  },
});

export const {
  useCreateVendorMutation,
  useGetVendorsListQuery,
  useDeleteVendorMutation,
  useFetchVendorQuery,
  useUpdateVendorMutation,
} = vendorService;

export default vendorService;
