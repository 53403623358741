import React, { useState, useEffect } from 'react'
import Layout from "../../components/Layout";
import TransactionForm from "../../sections/TransactionForm"
import ViewDetail from "../Transactions/viewDetail"
import { Breadcrumbs, OverlayLoading, Selector, MultiSelector } from "../../components/ui";

const NewTransaction = () => {
    const [isView, setIsView] = useState(false)
    const [detailData, setDetailData] = useState("")
    return (
        <Layout>
            <div className='flex flex-col min-h-[730px] py-10 bg-gray-50'>
                <div className="wrapWidth flex flex-col">
                    {
                        !isView ?
                            <TransactionForm
                                setIsView={setIsView}
                                setDetailData={setDetailData}
                            />
                            :
                            <ViewDetail
                                isView={isView}
                                setIsView={setIsView}
                                detailData={detailData}
                            />
                    }
                </div>
            </div>
        </Layout>
    )
}

export default NewTransaction