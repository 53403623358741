import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  DeviceTabletIcon,
  BanknotesIcon,
  ClipboardDocumentIcon
} from "@heroicons/react/24/outline";
import routes from "../../routes";
import SubMenus from "./SubMenus";

const navigation = [
  {
    name: "Dashboard",
    href: "/dashboard/home",
    icon: HomeIcon,
    current: ["/dashboard/home"],
  },
  {
    name: "All Transactions",
    href: routes.transactions,
    icon: FolderIcon,
    current: [routes.transactions],
  },
  {
    name: "Send Money",
    href: routes.addSendMoney,
    icon: BanknotesIcon,
    current: [routes.addSendMoney],
  },

  // {
  //   name: "Manage Vendors",
  //   href: routes.vendors,
  //   icon: FolderIcon,
  //   children: [
  //     {
  //       name: "All Vendors",
  //       href: routes.vendors,
  //     },
  //     { name: "Add New Vendor", href: routes.addNewVendors },
  //   ],
  //   current: [routes.vendors, routes.addNewVendors],
  // },
  // {
  //   name: "Notes Label",
  //   href: routes.notes,
  //   icon: ClipboardDocumentIcon,
  //   children: [
  //     {
  //       name: "All Notes",
  //       href: routes.notes,
  //     },
  //     { name: "Add New Note", href: routes.addNewNotes },
  //   ],
  //   current: [routes.notes, routes.addNewNotes],
  // },
  // { name: "Reports", href: "/", icon: ChartPieIcon },
];

function Content() {
  const pathname = useLocation();

  return (
    <div className="flex grow flex-col gap-y-5 overflow-y-auto lg:border-r lg:border-gray-200 bg-white px-6 pb-4">
      <div className="flex h-20 shrink-0 items-center">
        <Link to={"/"}>
          <img className="h-[60px] w-auto" src="/images/logo.png" alt="logo" />
        </Link>
      </div>
      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="-mx-2 space-y-2">
              {navigation.map((item) => {
                let isActive = item.current
                  ? item.current.includes(pathname)
                  : "";
                return (
                  <li key={item.name}>
                    {!item.children ? (
                      <NavLink
                        to={item.href}
                        className={`${isActive
                          ? "bg-gray-100 text-gray-900 font-semibold"
                          : "text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                          } navItem active1 group flex gap-x-3 rounded-md p-2 text-sm leading-6`}
                      >
                        <item.icon
                          className={`${isActive
                            ? "text-gray-900"
                            : "text-gray-700 group-hover:text-gray-900"
                            } h-6 w-6 shrink-0`}
                          aria-hidden="true"
                        />
                        {item.name}
                      </NavLink>
                    ) : (
                      <SubMenus item={item} isActive={isActive} />
                    )}
                  </li>
                );
              })}
            </ul>
          </li>
          <li className="mt-auto">
            <a
              href="#"
              className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm leading-6 text-gray-700 hover:bg-gray-50 hover:text-gray-900"
            >
              <Cog6ToothIcon
                className="h-6 w-6 shrink-0 text-gray-700 group-hover:text-gray-900"
                aria-hidden="true"
              />
              Settings
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Content;
