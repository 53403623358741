import React, { useState } from 'react';
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import routes from '../routes';

const CreditCardForm = ({ setOpenModal }) => {
    const navigation = useNavigate();
    const [cardNumber, setCardNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cardHolderName, setCardHolderName] = useState('');
    const [cvc, setCVC] = useState('');

    const handleCardNumberChange = (e) => {
        setCardNumber(e.target.value);
    };

    const handleExpiryDateChange = (e) => {
        setExpiryDate(e.target.value);
    };

    const handleCardHolderNameChange = (e) => {
        setCardHolderName(e.target.value);
    };

    const handleCVCChange = (e) => {
        setCVC(e.target.value);
    };
    const handleClose = () => {
        setOpenModal(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        navigation(routes.transactions);
    }

    return (
        <form onSubmit={handleSubmit} className="max-w-md mx-auto w-full flex flex-col relative">
            <div className="w-full flex items-center justify-between border-b-[1px] border-gray-200 py-4 mb-6">
                <div className="text-xl font-semibold text-gray-900">
                    Credit Card Information
                </div>
                <button
                    className="text-gray-900 hover:text-red-600"
                    onClick={handleClose}
                >
                    <XMarkIcon className="h-7 w-7" />
                </button>
            </div>
            <div className="mb-4">
                <label htmlFor="cardNumber" className="block text-sm font-medium text-gray-600">
                    Card Number
                </label>
                <input
                    type="text"
                    id="cardNumber"
                    className="input"
                    placeholder="1234 5678 9012 3456"
                    value={cardNumber}
                    required
                    onChange={handleCardNumberChange}
                />
            </div>

            <div className="mb-4 flex justify-between gap-4">
                <div>
                    <label htmlFor="expiryDate" className="block text-sm font-medium text-gray-600">
                        Expiry Date
                    </label>
                    <input
                        type="text"
                        id="expiryDate"
                        className="input"
                        placeholder="MM/YY"
                        value={expiryDate}
                        required
                        onChange={handleExpiryDateChange}
                    />
                </div>

                <div>
                    <label htmlFor="cvc" className="block text-sm font-medium text-gray-600">
                        CVC
                    </label>
                    <input
                        type="text"
                        id="cvc"
                        className="input"
                        placeholder="CVC"
                        value={cvc}
                        required
                        onChange={handleCVCChange}
                    />
                </div>
            </div>

            <div className="mb-4">
                <label htmlFor="cardHolderName" className="block text-sm font-medium text-gray-600">
                    Cardholder Name
                </label>
                <input
                    type="text"
                    id="cardHolderName"
                    className="input"
                    placeholder="John Doe"
                    value={cardHolderName}
                    required
                    onChange={handleCardHolderNameChange}
                />
            </div>

            <button type="submit" className="btn buttonPrimary text-white mb-5 mt-3">Submit</button>
        </form>
    );
};

export default CreditCardForm;
