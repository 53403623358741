import React, { useState, useEffect } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  useCreateNameMutation,
  useUpdateNameMutation,
} from "../store/services/nameService";

const AddNameForm = ({ setOpenModal, isEdit, prevData, setSelectedName }) => {
  const { userInfo } = useSelector((state) => state.authReducer);
  const [formData, setFormData] = useState({
    userId: userInfo ? userInfo?.id : "",
    name: "",
    phone: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (isEdit) {
      setFormData(prevData);
    } else {
      setFormData({
        ...formData,
        userId: userInfo ? userInfo?.id : "",
        name: "",
        phone: "",
      });
    }
  }, []);

  const [submitName, response] = useCreateNameMutation();
  const { isError, isLoading, isSuccess, data, error } = response;
  //   console.log("new name added resp....", data);
  const [submitUpdatedName, updatedResponse] = useUpdateNameMutation();
  const {
    isError: updatedISError,
    isLoading: updatedIsLoading,
    isSuccess: updatedIsSuccess,
    data: updatedData,
    error: updatedError,
  } = updatedResponse;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEdit) {
      submitUpdatedName(formData);
    } else {
      submitName(formData);
    }
  };

  useEffect(() => {
    if (!isLoading && isSuccess) {
      toast.success("Name created successfully!");
      setOpenModal(false);
      setSelectedName(data.data);
    }
    if (isError) {
      toast.error(error.data.message);
    }
  }, [response]);

  useEffect(() => {
    if (!updatedIsLoading && updatedIsSuccess) {
      toast.success("Name updated successfully!");
      setOpenModal(false);
      window.location.reload();
    }
    if (updatedISError) {
      toast.error(updatedError.data.message);
    }
  }, [updatedResponse]);

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-md mx-auto w-full flex flex-col relative p-4"
    >
      <div className="w-full flex items-center justify-between border-b-[1px] border-gray-200 py-4 mb-6">
        <div className="text-xl font-semibold text-gray-900">
          {isEdit ? "Edit Name" : "Add New Name"}
        </div>
        <button
          className="text-gray-900 hover:text-red-600"
          onClick={handleClose}
        >
          <XMarkIcon className="h-7 w-7" />
        </button>
      </div>
      <div className="mb-4">
        <label
          htmlFor="name"
          className="block text-sm font-medium text-gray-600"
        >
          Name *
        </label>
        <input
          type="text"
          id="name"
          name="name"
          className="input"
          placeholder="Name..."
          value={formData.name}
          required
          onChange={handleInputChange}
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="phone"
          className="block text-sm font-medium text-gray-600"
        >
          Phone Number
        </label>
        <input
          type="text"
          id="phone"
          name="phone"
          className="input"
          placeholder="Phone Number"
          value={formData.phone}
          required
          onChange={handleInputChange}
        />
      </div>

      <button type="submit" className="btn buttonPrimary text-white mb-5 mt-3">
        Submit
      </button>
    </form>
  );
};

export default AddNameForm;
