import React, { useState, useEffect } from "react";
import { useGetNamesListQuery } from "../../store/services/nameService";

function SearchInput({ selectedName, setSelectedName }) {
  const { data: namesList } = useGetNamesListQuery();

  const [inputValue, setInputValue] = useState(selectedName);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    setSuggestions(namesList?.data.map((item) => item.name));
  }, [namesList]);

  const handleChange = (event) => {
    const { value } = event.target;
    setInputValue(value);

    setShowSuggestions(true); // Show suggestions when typing
    // Filter suggestions based on input value
    // const filteredData = namesList?.data
    //   .map((item) => item.name)
    //   .filter((name) => name.toLowerCase().includes(value.toLowerCase()))
    //   .slice(0, 3);
    const filteredData = namesList?.data.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );

    setSuggestions(filteredData.slice(0, 3));
  };

  const handleSelectSuggestion = (selectedSuggestion) => {
    setInputValue(selectedSuggestion);
    setSelectedName(selectedSuggestion);
    setShowSuggestions(false);
  };

  const handleClearInput = () => {
    setInputValue("");
    setShowSuggestions(false);
  };
  useEffect(() => {
    document.addEventListener("click", () => {
      setShowSuggestions(false);
    });
  }, []);
  return (
    <div className="flex w-full flex-col">
      <input
        type="text"
        value={inputValue.name}
        onChange={handleChange}
        // onFocus={() => setShowSuggestions(true)}
        placeholder="Type Name..."
        className="input"
      />
      {showSuggestions && (
        <ul className="bg-gray-200 rounded-md p-2">
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              className="mb-2"
              onClick={() => handleSelectSuggestion(suggestion)}
            >
              {suggestion.name}
            </li>
          ))}
        </ul>
      )}
      {/* {inputValue && <button onClick={handleClearInput}>Clear</button>} */}
    </div>
  );
}

export default SearchInput;
