import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const transactionService = createApi({
  reducerPath: "transactions",
  tagTypes: "transaction",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const authorization = reducers.authReducer?.userToken;
      headers.set("authorization", authorization ? `Bearer ${authorization}` : "");
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => {
    return {
      //   Create New Transaction
      createTransaction: builder.mutation({
        query: (data) => {
          return { url: "/api/create-transaction", method: "POST", body: data };
        },
        invalidatesTags: ["transaction"],
      }),


      //   Get the List of Transactions
      getTransactionsList: builder.query({
        query: () => {
          return {
            url: "/api/allTransactions",
            method: "GET",
          };
        },
        providesTags: ["transaction"],
      }),

      //   Get the Single Transaction
      fetchTransaction: builder.query({
        query: (id) => {
          return {
            url: `/api/transaction/${id}`,
            method: "GET",
          };
        },
        providesTags: ["transaction"],
      }),

    };
  },
});

export const {
  useCreateTransactionMutation,
  useGetTransactionsListQuery,
  useFetchTransactionQuery,
} = transactionService;

export default transactionService;
