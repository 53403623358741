import React, { useState } from "react";
import { useSelector } from "react-redux";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import toast, { Toaster } from 'react-hot-toast';

const Layout = ({ children }) => {
  const [openSidebar, setOpenSidebar] = useState(false);
  // const { openSidebar } = useSelector((state) => state.globalReducer);
  return (
    <main className="w-full h-full">
      <Toaster />
      <div>
        <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
        <div className="lg:pl-72">
          <Header openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
          <main>{children}</main>
        </div>
      </div>
    </main>
  );

};
export default Layout;
