import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const customStyles = {
    option: (defaultStyles, state) => ({
        ...defaultStyles,
        fontSize: '15px',
        fontFamily: 'Inter',
        color: state.isSelected ? '#fff' : '#181818',
        backgroundColor: state.isSelected ? '#181818' : '#fff',
        '&:hover': {
            backgroundColor: '#f8f8f8',
            cursor: 'pointer',
            color: 'inherit',
        },
    }),

    control: (defaultStyles) => ({
        ...defaultStyles,
        width: '100%',
        padding: '4px 10px',
        height: 44,
        borderColor: '#e5e7eb',
        borderRadius: '0.5rem',
        boxShadow: 'none',
        '&:focus-within': {
            borderColor: 'rgba(17, 24, 39, 1)',
            boxShadow: '0 0 0 3px rgba(17, 24, 39, 0.1)',
        },
    }),
    singleValue: (defaultStyles) => ({
        ...defaultStyles,
        color: '#000',
        padding: '4px 0px',
        height: '100%',
        fontSize: '15px',
        fontFamily: 'Inter',
        '&:focus': {
            outline: 'none',
            borderColor: 'none',
            border: 'none',
        },
    }),
};

export default function MultiSelector({
    selectedOptions,
    setSelectedOptions,
    className,
    options,
    ...rest
}) {
    return (
        <div className={className}>
            <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                styles={customStyles}
                defaultValue={selectedOptions}
                value={selectedOptions}
                onChange={setSelectedOptions}
                options={options}
                {...rest}
            />
        </div>
    );
}
