import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
const userToken = localStorage.getItem("userKey");
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

function verifyToken(keyName) {
  const storage = localStorage.getItem(keyName);
  if (storage) {
    const decodeToken = jwtDecode(storage);
    const expiresIn = new Date(decodeToken.exp * 1000);
    if (new Date() > expiresIn) {
      localStorage.removeItem(keyName);
      return null;
    } else {
      return storage;
    }
  } else {
    return null;
  }
}

const authReducer = createSlice({
  name: "authReducer",
  initialState: {
    userToken: userToken ? userToken : null,
    user: verifyToken('userKey'),
    userInfo: userInfo ? userInfo : null,
  },
  reducers: {
    setUserToken: (state, action) => {
      state.userToken = action.payload;
      state.user = jwtDecode(action.payload);
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    logout: (state, { payload }) => {
      localStorage.removeItem(payload);
      state.userToken = null;
    },
  },
});
export const { setUserToken, setUserInfo, logout } = authReducer.actions;
export default authReducer.reducer;
