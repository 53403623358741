import { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { loginValidation } from "../../utiles/authValidations";
import { OverlayLoading } from "../../components/ui";
import { Input, Error } from "../../components/form";
import { useUserLoginMutation } from "../../store/services/authService";
import { setUserToken, setUserInfo } from "../../store/reducers/authReducer";

const Login = () => {
  const navigate = useNavigate();
  const initialValues = {
    email: "",
    password: "",
  };

  const [submitLogin, response] = useUserLoginMutation();
  const { isError, isLoading, isSuccess, data, error } = response;

  const onSubmit = async () => {
    submitLogin({
      email: values.email,
      password: values.password,
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginValidation,
    onSubmit,
  });

  const { handleChange, handleBlur, handleSubmit, values, errors } = formik;

  const dispatch = useDispatch();
  useEffect(() => {
    if (response.isSuccess) {
      localStorage.setItem("userKey", data?.token);
      dispatch(setUserToken(data?.token));
      localStorage.setItem("userInfo", JSON.stringify(data));
      dispatch(setUserInfo(data));
      navigate("/dashboard/home");
      toast.success("Login Successfully!");
    }
    if (isError) {
      toast.error(error.data.message);

    }
  }, [response]);

  return (
    <>
      <Toaster />
      {isLoading && <OverlayLoading />}
      <div className="flex min-h-screen flex-1">
        <div className="min-h-screen flex flex-1 flex-col justify-center px-4 py-10 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto h-full w-full flex flex-1 flex-col justify-center max-w-sm lg:w-96">
            <img className="h-auto w-24" src="/images/logo.png" alt="logo" />
            <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Sign in to your account
            </h2>

            <div className="mt-10">
              <form onSubmit={handleSubmit} className="space-y-5">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Username or Email
                  </label>
                  <div className="mt-2">
                    <Input
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      isError={errors.email && formik.touched.email}
                    />
                    {errors.email && formik.touched.email && (
                      <Error value={errors.email} />
                    )}
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                  <div className="mt-2">
                    <Input
                      type="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      isError={errors.password && formik.touched.password}
                    />
                    {errors.password && formik.touched.password && (
                      <Error value={errors.password} />
                    )}
                  </div>
                </div>

                <div className="!mt-10">
                  <button type="submit" className="buttonPrimary">
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>

        </div>
        <div className=" bg-[#FFA92D] relative hidden w-0 flex-1 lg:block">
          {/* <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/images/poster-3.jpg"
            alt=""
          /> */}
        </div>
      </div>
    </>
  );
};

export default Login;
