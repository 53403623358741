import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import Layout from '../../components/Layout'
import routes from "../../routes";
import CreditCardForm from "../../components/CreditCardForm"
import { Breadcrumbs, SpinLoader, Modal } from '../../components/ui';

const ViewDetail = ({ isView, setIsView, detailData }) => {
    console.log("detailData...", detailData)
    const navigation = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const pages = [
        { name: "Transactions", href: routes.transactions, current: false },
        {
            name: isView ? "Send Money Detail View" : "Send Money",
            href: "",
            current: true,
        },
    ];
    return (
        // <Layout>
        <div className='w-full min-h-screen bg-gray-50 margins'>
            <div className="mb-8">
                <Breadcrumbs pages={pages} />
            </div>
            <div className="w-full flex justify-center">
                <div className="wrapper bg-white">
                    <div className="w-full flex items-center px-6 py-5 border-solid border-b-[1px] border-gray-200">
                        <div className="text-2xl font-semibold text-zinc-900">
                            Send Money Detail View
                        </div>
                    </div>
                    <div className="w-full px-6 py-10">
                        <div className='flex flex-col w-full gap-1'>
                            <div className='flex items-center w-full p-3 bg-gray-100'>
                                <div className='flex flex-[0.4] font-semibold text-base'>Amount</div>
                                <div className='flex flex-1'>{detailData ? detailData.amount : "-"}</div>
                            </div>
                            <div className='flex items-center w-full p-3'>
                                <div className='flex flex-[0.4] font-semibold text-base'>Name</div>
                                <div className='flex flex-1'>{detailData ? detailData?.nameDetail.name : "-"}</div>
                            </div>
                            <div className='flex items-center w-full p-3 bg-gray-100'>
                                <div className='flex flex-[0.4] font-semibold text-base'>Exchanged Rate</div>
                                <div className='flex flex-1'>{detailData ? detailData.exchangedValue : "-"}</div>
                            </div>
                            <div className='flex items-center w-full p-3'>
                                <div className='flex flex-[0.4] font-semibold text-base'>{detailData?.businessCategory !== "" ? "Business Category" : "Vendor"}</div>
                                <div className='flex flex-1'>
                                    {detailData.businessCategory !== "" ?
                                        <span>
                                            {detailData ? detailData.businessCategory : "-"}
                                        </span>
                                        : <span>
                                            {detailData ? detailData.vendor : "-"}
                                        </span>}
                                </div>
                            </div>
                            <div className='flex items-center w-full p-3 bg-gray-100'>
                                <div className='flex flex-[0.4] font-semibold text-base'>Notes</div>
                                <div className='flex flex-1'>{detailData ? detailData.notes : "-"}</div>
                            </div>
                        </div>
                        <div className="w-full flex items-center gap-x-12 mt-10">
                            <button
                                type="button"
                                className="buttonPrimary min-w-[180px] max-w-fit h-[44px]"
                                onClick={(e) => setOpenModal(true)}
                            >
                                Submit
                            </button>
                            <button
                                type="button"
                                onClick={() => navigation(routes.addNewTransaction)}
                                className="text-base font-medium text-gray-900 max-w-fit h-[44px]"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                    <Modal open={openModal} setOpen={setOpenModal}>
                        <CreditCardForm setOpenModal={setOpenModal} />
                    </Modal>
                </div>
            </div>
        </div>
        // </Layout>
    )
}

export default ViewDetail;

