import React, { useState } from "react";

const CurrencyConverter = ({ formData, setFormData }) => {
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const [amount, setAmount] = useState(0);
  console.log("selectedCurrency", selectedCurrency);

  const exchangeRates = {
    USD: 0.0064,
    JMD: 157.1,
  };

  const handleCurrencyChange = (e) => {
    setSelectedCurrency(e.target.value);
  };

  const handleAmountChange = (e) => {
    const newAmount = e.target.value;
    const exchangeRate = exchangeRates[selectedCurrency];
    const exchangedValue = (parseFloat(newAmount) * exchangeRate).toFixed(2);
    setFormData((prevFormData) => ({
      ...prevFormData,
      amount: newAmount,
      exchangedValue: exchangedValue,
      conversionRate: exchangeRate,
    }));
  };

  // const calculateExchangedAmount = () => {
  //     const exchangeRate = exchangeRates[selectedCurrency];
  //     return (parseFloat(formData.amount) * exchangeRate).toFixed(2);
  // };
  const calculateExchangedAmount = () => {
    const exchangeRate = exchangeRates[selectedCurrency];
    const exchangedAmount = (
      parseFloat(formData.amount) * exchangeRate
    ).toFixed(2);
    return Number(exchangedAmount).toLocaleString(); // Add thousands separators
  };

  return (
    <div className="flex flex-col">
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-8">
        <div className="currency-input-box flex items-center w-full gap-2 input !rounded-full">
          <div className="flex items-center justify-center  w-6">
            {selectedCurrency === "USD" ? (
              <img src="/images/usd.png" alt="flag" className="h-full w-full" />
            ) : (
              <img src="/images/jmd.png" alt="flag" className="h-full w-full" />
            )}
          </div>
          <input
            type="number"
            className="w-full "
            placeholder="Enter amount"
            value={formData.amount}
            onChange={handleAmountChange}
          />
          <select
            className="border-l="
            value={selectedCurrency}
            onChange={handleCurrencyChange}
          >
            <option value="USD">USD</option>
            <option value="JMD">JMD</option>
          </select>
        </div>
        <div className="currency-input-box flex items-center w-full gap-2 input !rounded-full">
          <div className="flex items-center justify-center  w-6">
            {selectedCurrency === "USD" ? (
              <img src="/images/jmd.png" alt="flag" className="h-full w-full" />
            ) : (
              <img src="/images/usd.png" alt="flag" className="h-full w-full" />
            )}
          </div>
          <span className="w-full overflow-hidden">
            {calculateExchangedAmount()}
          </span>
          <span className="">{selectedCurrency === "USD" ? "JMD" : "USD"}</span>
        </div>
      </div>
      <div className="flex gap-2 justify-between mt-1 flex-col sm:flex-row">
        <p className="text-xs">
          Exchange Rate: 1 {selectedCurrency} ={" "}
          {exchangeRates[selectedCurrency]}
        </p>
        <p className="text-xs">
          Exchanged Amount: {calculateExchangedAmount()}
        </p>
      </div>
    </div>
  );
};

export default CurrencyConverter;
