import React from 'react'
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import Layout from '../../components/Layout'
import { SpinLoader, Table } from '../../components/ui';
import { useGetTransactionsListQuery } from '../../store/services/transcationService';

const Transactions = () => {
    const { isLoading, data } = useGetTransactionsListQuery();
    const columns = [
        {
            name: "Sr No.",
            selector: (row, key) => (key + 1),
            sortable: true,
            width: "110px",
        },
        {
            name: "Amount",
            selector: (row) => row?.amount,
            minWidth: "180px",
            wrap: true,
        },
        {
            name: "Name",
            selector: (row) => row?.nameStore?.name,
            minWidth: "180px",
            wrap: true,
        },
        {
            name: "Vendor Name",
            selector: (row) => row?.vendor,
            minWidth: "180px",
        },
        {
            name: "Business Category ",
            selector: (row) => row?.businessCategory,
            minWidth: "180px",
            wrap: true,
        },
        {
            name: "Pin Code",
            selector: (row) => row?.pinCode,
            width: "130px",
        },

        {
            name: "Notes",
            selector: (row) => row.notes,
            minWidth: "180px",
        },
        {
            name: "QR Code",
            width: "110px",
            cell: (row) => (
                <div className="p-1">
                    <img
                        src={`${process.env.REACT_APP_API_URL}${row.qrCodePath}`}
                        alt="imge"
                        className="h-20 w-20 object-contain bg-gray-50"
                    />
                </div>
            ),
        },
        {
            name: "Actions",
            width: "100px",
            cell: (row) => (
                <div className="flex items-center gap-x-2">
                    <button className="h-8 w-8 rounded-full bg-gray-900 flex items-center justify-center hover:opacity-90">
                        <PencilIcon className="h-4 w-4 text-white" />
                    </button>
                    <button
                        className="h-8 w-8 rounded-full bg-red-600 flex items-center justify-center hover:opacity-90"
                    >
                        <TrashIcon className="h-4 w-4 text-white" />
                    </button>
                </div>
            ),
        },
    ];
    return (
        <Layout>
            <div className='flex flex-col min-h-[730px] py-10 bg-themeColor2'>
                <div className='wrapWidth'>
                    <>
                        <div className="w-full flex items-center mb-8">
                            <div className="flex-1 text-3xl font-bold text-gray-900">
                                All Transaction
                            </div>
                        </div>
                        {isLoading ? (
                            <div className="w-full py-6 flex items-center justify-center">
                                <SpinLoader />
                            </div>
                        ) : (
                            <Table
                                columns={columns}
                                data={data?.data?.length ? data?.data : []}
                                pagination={true}
                            />
                        )}
                    </>
                </div>
            </div>
        </Layout>
    )
}

export default Transactions