import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Breadcrumbs, OverlayLoading } from "../../components/ui";
import { Input } from "../../components/form";
import routes from "../../routes";
import { useCreateNoteMutation } from "../../store/services/notesService";


function NoteLabelForm({ isEditMode = false, prevData }) {
  const { userInfo } = useSelector((state) => state.authReducer);
  const navigation = useNavigate();

  const pages = [
    { name: "Notes", href: routes.notes, current: false },
    {
      name: isEditMode ? "Edit Note" : "New Note",
      href: "",
      current: true,
    },
  ];
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    userId: userInfo ? userInfo?.id : "",
  })
  const handleInputData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const [loading, setLoading] = useState(false)
  const [submitNote, response] = useCreateNoteMutation();
  const { isError, isLoading, isSuccess, data, error } = response;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEditMode) {

    } else {
      submitNote(formData)
    }
  }

  useEffect(() => {
    if (!isLoading && isSuccess) {
      toast.success("Note created successfully!");
      navigation(routes.notes);
    }
    if (isError) {
      toast.error(error.data.message);
    }
  }, [response]);



  return (
    <>
      {isLoading && <OverlayLoading />}
      <div className="w-full min-h-screen bg-gray-50 margins">
        <div className="mb-8">
          <Breadcrumbs pages={pages} />
        </div>
        <div className="w-full flex justify-center">
          <div className="wrapper bg-white">
            <div className="w-full flex items-center px-6 py-5 border-solid border-b-[1px] border-gray-200">
              <div className="text-2xl font-semibold text-zinc-900">
                {isEditMode ? "Edit Note" : "New Note"}
              </div>
            </div>
            <div className="w-full px-6 py-10">
              <form onSubmit={handleSubmit}>
                <div className="w-full grid grid-cols-1 sm:grid-cols-1 gap-4 md:gap-8">
                  <div className="w-full flex flex-col gap-y-2">
                    <div className="text-sm font-medium">Note & Label Name</div>
                    <Input
                      name="name"
                      onChange={handleInputData}
                      value={formData.name}
                    />
                  </div>
                  <div className="w-full flex flex-col gap-y-2">
                    <div className="text-sm font-medium">Description</div>
                    <textarea
                      className="input"
                      name="description"
                      value={formData.description}
                      onChange={handleInputData}
                      placeholder="Type here..."
                    />

                  </div>
                </div>

                <div className="w-full flex items-center gap-x-12 mt-10">
                  <button
                    type="submit"
                    className="buttonPrimary min-w-[180px] max-w-fit h-[44px]"
                  >
                    {isEditMode ? "Update Note" : "Create Note"}
                  </button>
                  <button
                    type="button"
                    onClick={() => navigation(routes.notes)}
                    className="text-base font-medium text-gray-900 max-w-fit h-[44px]"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NoteLabelForm;
