import React from 'react'
import Layout from "../../components/Layout";
import NoteForm from "../../sections/NoteLabelForm"

const NewNoteLabel = () => {
    return (
        <Layout>
            <div className='flex flex-col min-h-[730px] py-10 bg-gray-50'>
                <div className="wrapWidth flex flex-col">
                    <NoteForm />
                </div>
            </div>
        </Layout>
    )
}

export default NewNoteLabel