import React, { useState, useEffect } from "react";

function SearchBusinessVendorInput({ selectedVendor, setSelectedVendor }) {
  const vendorsList = [
    {
      name: "Vendor 1",
      value: "v1",
      type: "vendor",
    },
    {
      name: "Vendor 2",
      value: "v2",
      type: "vendor",
    },
    {
      name: "Business 1",
      value: "b1",
      type: "business",
    },
    {
      name: "Vendor 3",
      value: "v3",
      type: "vendor",
    },
    {
      name: "Business 2",
      value: "b2",
      type: "business",
    },
  ];
  const [inputValue, setInputValue] = useState(selectedVendor);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  // useEffect(() => {
  //   setSuggestions(vendorsList.map((item) => item.name));
  // }, [vendorsList]);

  const handleChange = (event) => {
    const { value } = event.target;
    setInputValue(value);

    setShowSuggestions(true); // Show suggestions when typing
    const filteredData = vendorsList.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );

    setSuggestions(filteredData.slice(0, 3));
  };

  const handleSelectSuggestion = (selectedSuggestion) => {
    setInputValue(selectedSuggestion);
    setSelectedVendor(selectedSuggestion);
    setShowSuggestions(false);
  };

  useEffect(() => {
    document.addEventListener("click", () => {
      setShowSuggestions(false);
    });
  }, []);
  return (
    <div className="flex w-full flex-col">
      <input
        type="text"
        value={inputValue.name}
        onChange={handleChange}
        placeholder="Select Vender..."
        className="input"
      />
      {showSuggestions && (
        <ul className="bg-gray-200 rounded-md p-2">
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              className="mb-2"
              onClick={() => handleSelectSuggestion(suggestion)}
            >
              {suggestion.type === "vendor" ? "*" : "#"} {suggestion.name}
            </li>
          ))}
        </ul>
      )}
      {/* {inputValue && <button onClick={handleClearInput}>Clear</button>} */}
    </div>
  );
}

export default SearchBusinessVendorInput;
