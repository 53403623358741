export default Object.freeze({
  transactions: "/transactions/list",
  addSendMoney: "/transactions/send-money",
  addNewTransaction: "/transactions/create-transaction",
  users: "/users/list",
  addNewUser: "/users/create-user",
  vendors: "/vendors/list",
  addNewVendors: "/vendors/add-new-vendors",
  notes: "/notes/list",
  addNewNotes: "/notes/add-new-note",
  allotments: "/allotments",
  addNewAllotment: "/allotments/create-allotment",
  customerAllotmentDetail: "/allotments/customer-allotment-detail",
});
