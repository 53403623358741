import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { PlusIcon, PencilIcon } from "@heroicons/react/24/outline";
import {
  Breadcrumbs,
  OverlayLoading,
  Selector,
  CustomSelector,
  Modal,
} from "../../components/ui";
import CurrencyConverter from "../../components/ui/CurrencyConverter";
import { Input, Error } from "../../components/form";
import AddNameForm from "../../components/AddNameForm";
import routes from "../../routes";
import { useGetVendorsListQuery } from "../../store/services/vendorService";
import { useGetNamesListQuery } from "../../store/services/nameService";
import { useCreateTransactionMutation } from "../../store/services/transcationService";
import Select, { components } from "react-select";

function TransactionForm({ isEditMode = false, setDetailData, setIsView }) {
  const { Option, SingleValue } = components;
  const [chainId, setChainId] = useState(1);
  const { userInfo } = useSelector((state) => state.authReducer);
  const navigation = useNavigate();

  const pages = [
    { name: "Transactions", href: routes.transactions, current: false },
    {
      name: isEditMode ? "Edit Transaction" : "Send Money",
      href: "",
      current: true,
    },
  ];

  const { data: namesList } = useGetNamesListQuery();
  const [formData, setFormData] = useState({
    userId: userInfo ? userInfo?.id : "",
    vendor: "",
    businessCategory: "",
    amount: 0,
    exchangedValue: "",
    conversionRate: "",
    nameStore: "",
    nameDetail: "",
    notes: "",
  });

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openAddNameModal, setOpenAddNameModal] = useState(false);
  const [nameIsEdit, setNameIsEdit] = useState(false);
  const [submitFormData, response] = useCreateTransactionMutation();
  const { isError, isLoading, isSuccess, error } = response;

  useEffect(() => {
    if (!isLoading && isSuccess) {
      // navigation(routes.transactions);
      // setOpenModal(true)
      // toast.success("Transaction performed successfully!");
    }
    if (isError) {
      toast.error(error.data.message);
    }
  }, [response]);

  // console.log("formData...", formData)
  const handleSubmit = (e) => {
    setDetailData(formData);
    e.preventDefault();
    if (
      formData.amount !== 0 &&
      formData.exchangedValue !== "" &&
      formData.nameStore !== ""
    ) {
      submitFormData(formData);
      setIsView(true);
    } else {
      toast.error("Please first input all data!");
    }
  };
  const [selectedName, setSelectedName] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    setFormData({
      ...formData,
      vendor: "",
      businessCategory: "",
    });
  };

  useEffect(() => {
    setFormData({
      ...formData,
      nameStore: selectedName?._id,
      nameDetail: selectedName,
    });
  }, [selectedName]);

  return (
    <>
      {isLoading && <OverlayLoading />}
      <div className="w-full min-h-screen bg-gray-50 margins">
        <div className="mb-8">
          <Breadcrumbs pages={pages} />
        </div>
        <div className="w-full flex justify-center">
          <div className="wrapper bg-white">
            <div className="w-full flex items-center px-6 py-5 border-solid border-b-[1px] border-gray-200">
              <div className="text-2xl font-semibold text-zinc-900">
                {isEditMode ? "Edit Money" : "Send Money"}
              </div>
            </div>
            <div className="w-full px-6 py-10">
              <form onSubmit={handleSubmit} className="flex flex-col gap-8">
                <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-8">
                  <div className="w-full flex flex-col gap-y-2">
                    <div className="text-sm font-medium">Amount</div>
                    <CurrencyConverter
                      formData={formData}
                      setFormData={setFormData}
                    />
                  </div>
                  <div className="w-full flex flex-col gap-y-2">
                    <div className="text-sm font-medium">Enter Names</div>
                    <div className="flex items-center w-full gap-4">
                      <CustomSelector
                        optionsList={namesList?.data}
                        setOpenAddNameModal={setOpenAddNameModal}
                        setSelectedValue={setSelectedName}
                      />
                      {selectedName !== "" && (
                        <button
                          type="button"
                          className="flex items-center justify-center text-base font-semibold h-10 w-10 rounded-md bg-gray-200"
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenAddNameModal(true);
                            setNameIsEdit(true);
                          }}
                        >
                          <PencilIcon className="h-5 w-5 text-black" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="flex items-center cursor-pointer">
                    <input
                      type="radio"
                      id="vendor"
                      name="vendor"
                      value="vendor"
                      checked={selectedOption === "vendor"}
                      onChange={handleOptionChange}
                      className="mr-2 h-5 w-5 cursor-pointer"
                    />
                    <label htmlFor="vendor" className="mr-4">
                      Vendor
                    </label>

                    <input
                      type="radio"
                      id="business"
                      name="business"
                      value="business"
                      checked={selectedOption === "business"}
                      onChange={handleOptionChange}
                      className="mr-2 h-5 w-5"
                    />
                    <label htmlFor="business">Business</label>
                  </div>
                </div>

                <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-8">
                  {selectedOption === "vendor" ? (
                    <div className="w-full flex flex-col gap-y-2">
                      <div className="text-sm font-medium">Vendor Name</div>
                      <Input
                        value={formData.vendor}
                        onChange={(e) =>
                          setFormData({ ...formData, vendor: e.target.value })
                        }
                        placeholder="Enter Vendor Name"
                      />
                      {/* <Selector
                        options={vendorsList?.data}
                        selectedOption={formData.vendor}
                        setSelectedOption={(e) => {
                          setFormData({ ...formData, vendor: e._id })
                        }}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option._id}
                      /> */}
                    </div>
                  ) : selectedOption === "business" ? (
                    <div className="w-full flex flex-col gap-y-2">
                      <div className="text-sm font-medium">
                        Business Category
                      </div>
                      <Input
                        value={formData.businessCategory}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            businessCategory: e.target.value,
                          })
                        }
                        placeholder="Enter Business Category"
                      />
                      {/* <Selector
                          options={businessCategory}
                          selectedOption={formData.vendor}
                          setSelectedOption={(e) => {
                            setFormData({ ...formData, businessCategory: e.label })
                          }}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                        /> */}
                    </div>
                  ) : null}
                </div>
                <div className="w-full grid grid-cols-1 sm:grid-cols-1 gap-4 md:gap-8">
                  <div className="w-full flex flex-col gap-y-2">
                    <div className="text-sm font-medium">Notes</div>
                    <textarea
                      className="input"
                      name="notes"
                      value={formData.notes}
                      onChange={(e) => {
                        setFormData({ ...formData, notes: e.target.value });
                      }}
                      placeholder="Edit or add more text here..."
                    />
                  </div>
                </div>
                <div className="w-full flex items-center gap-x-12 mt-10">
                  <button
                    type="submit"
                    className="buttonPrimary min-w-[180px] max-w-fit h-[44px]"
                  >
                    {isEditMode ? "Update Transaction" : "Create Transaction"}
                  </button>
                  <button
                    type="button"
                    onClick={() => navigation(routes.transactions)}
                    className="text-base font-medium text-gray-900 max-w-fit h-[44px]"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <Modal open={openModal} setOpen={setOpenModal}>
          <CreditCardForm setOpenModal={setOpenModal} />
        </Modal> */}
        <Modal open={openAddNameModal} setOpen={setOpenAddNameModal}>
          <AddNameForm
            setOpenModal={setOpenAddNameModal}
            isEdit={nameIsEdit}
            prevData={selectedName}
          />
        </Modal>
      </div>
    </>
  );
}

export default TransactionForm;
