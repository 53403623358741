import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const noteService = createApi({
  reducerPath: "notes",
  tagTypes: "note",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const authorization = reducers.authReducer?.userToken;
      headers.set("authorization", authorization ? `Bearer ${authorization}` : "");
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => {
    return {
      //   Create New NoteLabel
      createNote: builder.mutation({
        query: (data) => {
          return { url: "/api/create-noteLabel", method: "POST", body: data };
        },
        invalidatesTags: ["note"],
      }),

      //   Delete a Note
      deleteNote: builder.mutation({
        query: (id) => {
          return { url: `/api/noteLabel/${id}`, method: "DELETE" };
        },
        invalidatesTags: ["note"],
      }),

      //   Update the Note
      updateNote: builder.mutation({
        query: (data) => {
          return {
            url: `/api/noteLabel/${data.Id}`,
            method: "PUT",
            body: data,
          };
        },
        invalidatesTags: ["note"],
      }),

      //   Get the List of Notes
      getNotesList: builder.query({
        query: () => {
          return {
            url: "/api/allNoteLabels",
            method: "GET",
          };
        },
        providesTags: ["vendor"],
      }),

      //   Get the Single Note
      fetchNote: builder.query({
        query: (id) => {
          return {
            url: `/api/noteLabel/${id}`,
            method: "GET",
          };
        },
        providesTags: ["note"],
      }),

    };
  },
});

export const {
  useCreateNoteMutation,
  useGetNotesListQuery,
  useDeleteNoteMutation,
  useFetchNoteQuery,
  useUpdateNoteMutation,
} = noteService;

export default noteService;
