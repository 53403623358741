import React, { useState } from 'react'
import Select, { components } from 'react-select';

const CustomSelector = ({ optionsList, setOpenAddNameModal, setSelectedValue }) => {

    const CustomButton = () => (
        <button
            className="button bg-black text-white p-2"
            onClick={(e) => {
                e.stopPropagation()
                setOpenAddNameModal(true)
            }}
        >
            Add New Name
        </button>
    );
    const IconOption = (props) => {
        const { data } = props;
        return (
            <components.Option {...props}>
                {data.name}
            </components.Option>
        );
    };

    const customComponents = {
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Option: IconOption,
        Menu: (props) => (
            <components.Menu {...props} className="flex flex-col">
                {<CustomButton />}
                {props.children}
            </components.Menu>
        ),
    };

    const customStyles = {
        option: (provided) => ({
            ...provided,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            background: "#FFF",
            color: "#000"
        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        }),
    };
    const handleSelectChange = (selectedOption) => {
        setSelectedValue(selectedOption);
    };

    return (
        <Select
            styles={customStyles}
            components={customComponents}
            options={optionsList}
            onChange={handleSelectChange}
            isSearchable={false}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.name}
            className='w-full'
        />
    )
}

export default CustomSelector