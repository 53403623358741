import React, { useState } from "react";
import Select from "react-select";
import Modal from "../../components/Modal";
import Layout from "../../components/Layout";

const Main = () => {
  const [open, setOpen] = useState(false);

  return (
    <Layout>
      <div className="lading-page bg-themeColor2 h-screen flex min-h-[730px]">
        <div className="wrap wrapWidth flex flex-col">Home Page</div>
        {/* <Modal open={open} onClose={() => setOpen(false)}>
          <TableRowModel setOpen={setOpen} item={modelData} />
        </Modal> */}
      </div>
    </Layout>
  );
};

export default Main;
