import { configureStore } from "@reduxjs/toolkit";

import authService from "./services/authService";
import vendorService from "./services/vendorService";
import notesService from "./services/notesService";
import transactionService from "./services/transcationService";
import nameService from "./services/nameService";

import authReducer from "./reducers/authReducer";
import globalReducer from "./reducers/globalReducer";

const Store = configureStore({
  reducer: {
    [authService.reducerPath]: authService.reducer,
    [vendorService.reducerPath]: vendorService.reducer,
    [notesService.reducerPath]: notesService.reducer,
    [transactionService.reducerPath]: transactionService.reducer,
    [nameService.reducerPath]: nameService.reducer,

    authReducer: authReducer,
    globalReducer: globalReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([
    vendorService.middleware,
    notesService.middleware,
    transactionService.middleware,
    nameService.middleware,
  ]),
});

export default Store;
