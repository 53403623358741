import React, { useState } from 'react'
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import Layout from '../../components/Layout'
import { SpinLoader, Table } from '../../components/ui';
import { useGetNotesListQuery } from '../../store/services/notesService';

const NotesLabel = () => {
    const { isLoading, data } = useGetNotesListQuery();
    const columns = [
        {
            name: "Sr No.",
            selector: (row, key) => (key + 1),
            sortable: true,
            width: "110px",
        },
        {
            name: "Note Label",
            selector: (row) => row.name,
            minWidth: "180px",
        },
        {
            name: "Description",
            selector: (row) => row.description,
            minWidth: "180px",
        },

        {
            name: "Actions",
            width: "100px",
            cell: (row) => (
                <div className="flex items-center gap-x-2">
                    <button className="h-8 w-8 rounded-full bg-gray-900 flex items-center justify-center hover:opacity-90">
                        <PencilIcon className="h-4 w-4 text-white" />
                    </button>
                    <button
                        className="h-8 w-8 rounded-full bg-red-600 flex items-center justify-center hover:opacity-90"
                    >
                        <TrashIcon className="h-4 w-4 text-white" />
                    </button>
                </div>
            ),
        },
    ];

    return (
        <Layout>
            <div className='flex flex-col min-h-[730px] py-10 bg-themeColor2'>
                <div className='wrapWidth'>
                    <>
                        {isLoading ? (
                            <div className="w-full py-6 flex items-center justify-center">
                                <SpinLoader />
                            </div>
                        ) : (
                            <div>
                                <div className="w-full flex items-center mb-8">
                                    <div className="flex-1 text-3xl font-bold text-gray-900">
                                        All Notes Label
                                    </div>
                                </div>
                                <Table
                                    columns={columns}
                                    data={data?.data?.length ? data?.data : []}
                                    pagination={true}
                                />
                            </div>
                        )}

                    </>
                </div>
            </div>
        </Layout>
    )
}

export default NotesLabel