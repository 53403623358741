import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { PlusIcon, PencilIcon } from "@heroicons/react/24/outline";
import {
  Breadcrumbs,
  OverlayLoading,
  Selector,
  CustomSelector,
  Modal,
  SearchInput,
  SearchBusinessVendorInput,
} from "../../components/ui";
import CurrencyConverter from "../../components/ui/CurrencyConverter";
import { Input, Error } from "../../components/form";
import AddNameForm from "../../components/AddNameForm";
import routes from "../../routes";
import { useGetVendorsListQuery } from "../../store/services/vendorService";
import { useGetNamesListQuery } from "../../store/services/nameService";
import { useCreateTransactionMutation } from "../../store/services/transcationService";
import Select, { components } from "react-select";

function SendMoneyForm({ isEditMode = false, setDetailData, setIsView }) {
  const { Option, SingleValue } = components;
  const [chainId, setChainId] = useState(1);
  const { userInfo } = useSelector((state) => state.authReducer);
  const navigation = useNavigate();
  const pages = [
    { name: "Transactions", href: routes.transactions, current: false },
    {
      name: isEditMode ? "Edit Send Money" : "Send Money",
      href: "",
      current: true,
    },
  ];

  const { data: namesList } = useGetNamesListQuery();
  const [formData, setFormData] = useState({
    userId: userInfo ? userInfo?.id : "",
    vendor: "",
    businessCategory: "",
    amount: 0,
    exchangedValue: "",
    conversionRate: "",
    nameStore: "",
    nameDetail: "",
    notes: "",
  });

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [step, setStep] = useState(0);
  const [openAddNameModal, setOpenAddNameModal] = useState(false);
  const [nameIsEdit, setNameIsEdit] = useState(false);
  const [submitFormData, response] = useCreateTransactionMutation();
  const { isError, isLoading, isSuccess, error } = response;

  useEffect(() => {
    if (!isLoading && isSuccess) {
      // navigation(routes.transactions);
      // setOpenModal(true)
      // toast.success("Transaction performed successfully!");
    }
    if (isError) {
      toast.error(error.data.message);
    }
  }, [response]);

  // console.log("formData...", formData)
  const handleSubmit = (e) => {
    setDetailData(formData);
    e.preventDefault();
    if (
      formData.amount !== 0 &&
      formData.exchangedValue !== "" &&
      formData.nameStore !== ""
    ) {
      submitFormData(formData);
      setIsView(true);
    } else {
      toast.error("Please first input all data!");
    }
  };
  const [selectedName, setSelectedName] = useState("");
  const [selectedVendor, setSelectedVendor] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    setFormData({
      ...formData,
      vendor: "",
      businessCategory: "",
    });
  };

  useEffect(() => {
    setFormData({
      ...formData,
      vendor: selectedVendor?.name,
    });
  }, [selectedVendor]);

  useEffect(() => {
    setFormData({
      ...formData,
      nameStore: selectedName?._id,
      nameDetail: selectedName,
    });
  }, [selectedName]);

  console.log("selectedName...", selectedName);

  return (
    <>
      {isLoading && <OverlayLoading />}
      <div className="w-full min-h-screen bg-gray-50 margins">
        {/* <div className="mb-8">
          <Breadcrumbs pages={pages} />
        </div> */}
        <div className="w-full flex justify-center">
          <div className="wrapper bg-white">
            <div className="w-full flex items-center px-6 py-5 border-solid border-b-[1px] border-gray-200">
              <div className="text-2xl font-semibold text-zinc-900">
                {isEditMode ? "Edit Money" : "Send Money"}
              </div>
            </div>
            <div className="w-full px-3 py-8 sm:px-6 sm:py-10">
              <form onSubmit={handleSubmit} className="flex flex-col gap-8">
                {step === 0 ? (
                  <>
                    <div className="w-full grid grid-cols-1 sm:grid-cols-1 gap-4 md:gap-8">
                      <div className="w-full flex flex-col gap-y-2">
                        <div className="text-sm font-medium">You send</div>
                        <CurrencyConverter
                          formData={formData}
                          setFormData={setFormData}
                        />
                      </div>
                    </div>

                    <div className="w-full flex items-center gap-4 sm:gap-8 mt-10">
                      <button
                        type="button"
                        className="buttonPrimary min-w-[150px] max-w-fit h-[44px] !rounded-full"
                      >
                        Pay Bill
                      </button>
                      <button
                        type="button"
                        className="buttonPrimary min-w-[150px] max-w-fit h-[44px] !rounded-full"
                        onClick={(e) => setStep(step + 1)}
                      >
                        Send Money
                      </button>
                      {/* <button
                    type="submit"
                    className="buttonPrimary min-w-[180px] max-w-fit h-[44px]"
                  >
                    {isEditMode ? "Update Transaction" : "Create Transaction"}
                  </button>
                  <button
                    type="button"
                    onClick={() => navigation(routes.transactions)}
                    className="text-base font-medium text-gray-900 max-w-fit h-[44px]"
                  >
                    Cancel
                  </button> */}
                    </div>
                  </>
                ) : step === 1 ? (
                  <div className="flex flex-col">
                    <div className="flex flex-col gap-1">
                      Name Page
                      <div className="flex items-center w-full gap-3">
                        <SearchInput
                          selectedName={selectedName}
                          setSelectedName={setSelectedName}
                        />
                        <button
                          type="button"
                          className="flex items-center justify-center text-base font-semibold h-10 w-10 rounded-md bg-gray-200"
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenAddNameModal(true);
                            // setNameIsEdit(true);
                          }}
                        >
                          <PlusIcon className="h-5 w-5 text-black" />
                        </button>
                      </div>
                    </div>
                    <div className="w-full flex items-center justify-between gap-4 sm:gap-8 mt-32">
                      <button
                        type="button"
                        className="buttonPrimary min-w-[150px] max-w-fit h-[44px] !rounded-full"
                        onClick={(e) => setStep(step - 1)}
                      >
                        Back
                      </button>
                      <button
                        type="button"
                        className="buttonPrimary min-w-[150px] max-w-fit h-[44px] !rounded-full"
                        disabled={!selectedName?.name ? true : false}
                        onClick={(e) => setStep(step + 1)}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                ) : step === 2 ? (
                  <div className="flex flex-col">
                    <div className="flex flex-col gap-1">
                      Choose Business or Vendor
                      <div className="flex items-center w-full gap-3">
                        <SearchBusinessVendorInput
                          selectedVendor={selectedVendor}
                          setSelectedVendor={setSelectedVendor}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mt-12 gap-2">
                      <span className="">Selected Vender</span>
                      <h1 className="input text-base text-black font-bold">
                        {selectedVendor ? selectedVendor?.name : ""}
                      </h1>
                    </div>
                    <div className="w-full flex items-center justify-between gap-4 sm:gap-8 mt-32">
                      <button
                        type="button"
                        className="buttonPrimary min-w-[150px] max-w-fit h-[44px] !rounded-full"
                        onClick={(e) => setStep(step - 1)}
                      >
                        Back
                      </button>
                      <button
                        type="button"
                        className="buttonPrimary min-w-[150px] max-w-fit h-[44px] !rounded-full"
                        // disabled={!selectedName?.name ? true : false}
                        onClick={(e) => setStep(step + 1)}
                      >
                        {selectedVendor ? "Next" : "Skip"}
                      </button>
                    </div>
                  </div>
                ) : step === 3 ? (
                  <div className="flex flex-col">
                    <div className="w-full flex flex-col gap-y-2">
                      <div className="text-sm font-medium">Notes</div>
                      <textarea
                        className="input"
                        name="notes"
                        value={formData.notes}
                        onChange={(e) => {
                          setFormData({ ...formData, notes: e.target.value });
                        }}
                        placeholder="Edit or add more text here..."
                      />
                    </div>
                    <div className="w-full flex items-center justify-between gap-4 sm:gap-8 mt-32">
                      <button
                        type="button"
                        className="buttonPrimary min-w-[150px] max-w-fit h-[44px] !rounded-full"
                        onClick={(e) => setStep(step - 1)}
                      >
                        Back
                      </button>
                      <button
                        type="button"
                        className="buttonPrimary min-w-[150px] max-w-fit h-[44px] !rounded-full"
                        // disabled={!selectedName?.name ? true : false}
                        onClick={(e) => setStep(step + 1)}
                      >
                        {formData.notes !== "" ? "Next" : "Skip"}
                      </button>
                    </div>
                  </div>
                ) : step === 4 ? (
                  <div className="flex flex-col">
                    <div className="w-full flex flex-col gap-y-2">
                      <div className="text-sm font-medium">Review Order</div>
                      <div className="flex flex-col w-full gap-1">
                        <div className="flex items-center w-full p-3 bg-gray-100">
                          <div className="flex flex-[0.4] font-semibold text-base">
                            Amount
                          </div>
                          <div className="flex flex-1">
                            {formData.amount || "-"}
                          </div>
                        </div>
                        <div className="flex items-center w-full p-3">
                          <div className="flex flex-[0.4] font-semibold text-base">
                            Name
                          </div>
                          <div className="flex flex-1">
                            {formData ? formData?.nameDetail.name : "-"}
                          </div>
                        </div>
                        <div className="flex items-center w-full p-3 bg-gray-100">
                          <div className="flex flex-[0.4] font-semibold text-base">
                            Exchanged Rate
                          </div>
                          <div className="flex flex-1">
                            {formData ? formData.exchangedValue : "-"}
                          </div>
                        </div>
                        <div className="flex items-center w-full p-3 bg-gray-100">
                          <div className="flex flex-[0.4] font-semibold text-base">
                            Vender / Business
                          </div>
                          <div className="flex flex-1">
                            {formData ? formData.vendor : "-"}
                          </div>
                        </div>
                        <div className="flex items-center w-full p-3 bg-gray-100">
                          <div className="flex flex-[0.4] font-semibold text-base">
                            Notes
                          </div>
                          <div className="flex flex-1">
                            {formData ? formData.notes : "-"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full flex items-center justify-between gap-4 sm:gap-8 mt-32">
                      <button
                        type="button"
                        className="buttonPrimary min-w-[150px] max-w-fit h-[44px] !rounded-full"
                        onClick={(e) => setStep(step - 1)}
                      >
                        Back
                      </button>
                      <button
                        type="submit"
                        className="buttonPrimary min-w-[150px] max-w-fit h-[44px] !rounded-full"
                      >
                        Pay
                      </button>
                    </div>
                  </div>
                ) : null}
              </form>
            </div>
          </div>
        </div>
        {/* <Modal open={openModal} setOpen={setOpenModal}>
          <CreditCardForm setOpenModal={setOpenModal} />
        </Modal> */}
        <Modal open={openAddNameModal} setOpen={setOpenAddNameModal}>
          <AddNameForm
            setOpenModal={setOpenAddNameModal}
            isEdit={nameIsEdit}
            prevData={selectedName}
            setSelectedName={setSelectedName}
          />
        </Modal>
      </div>
    </>
  );
}

export default SendMoneyForm;
